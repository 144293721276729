<template>
  <div class="container">
    <div class="columns">
      <div class="column login-box card is-offset-3 is-6 content">
        <div class="p-md">
          <template v-if="state === 'initial'">
            <div class="has-text-centered">
              <h2 class="">
                Registration
              </h2>
              <!-- TODO: Set a correct wording here. -->
              <p>
                If you need help creating your new account visit the
                <router-link
                  target="_blank"
                  :to="{ name: 'faq' }"
                >
                  Vendor FAQ Page
                </router-link>
                or the
                <a
                  href="/Foster-Parent-Payment-Portal-Instructions.pdf"
                  target="_blank"
                >
                  Foster Parent FAQ file.
                </a>
              </p>
              <p>All fields except "Phone Extension" are required.</p>
            </div>
            <registration-form
              ref="register-form"
              :user-type="$USER"
              cur-screen="register"
              class="m-t-md"
              @form-submit="createUser"
            />
          </template>
          <template v-if="state === 'successful-pending-approval'">
            <div class="has-text-centered">
              <h2 class="">
                Registration
              </h2>
              <p>
                Your Vendor Payments account was created, but, still needs approval
                from any of the current active and approved users.
                We sent an e-mail notification with instructions to review and approve your account.
              </p>
              <router-link :to="{ name: 'login' }">
                Go to login
              </router-link>
            </div>
          </template>
          <template v-if="state === 'successful'">
            <div class="has-text-centered">
              <h2 class="">
                Registration
              </h2>
              <p>
                Your Vendor Payments account has been created!
                Please check your e-mail inbox for a confirmation email.
              </p>
              <router-link :to="{ name: 'login' }">
                Go to login
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RegistrationForm from '@/components/RegistrationForm';

export default {
  components: {
    RegistrationForm,
  },
  data() {
    return {
      state: 'initial',
      userObject: {},
    };
  },
  methods: {
    async createUser(userData) {
      const result = await this.$store.dispatch('users/register', userData);
      console.log('Register.vue createUser is running, result:', result, 'result.status:', result.status);
      if (result) {
        // this.$refs['register-form'].reset();
        this.$store.commit('users/cleanUserObject');

        if (result.status === 'PendingSelfValidation') {
          console.log('Register.vue createUser is running, result:', result);
          // Send the user to validate using amount
          this.$router.push({ name: 'amount', query: { userId: result.id }});
        } else if (result.status === 'PendingApproval') {
          this.state = 'successful-pending-approval';
        } else {
          this.state = 'successful';
        }
      }
    },
  },
};
</script>

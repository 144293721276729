<template>
  <div>
    <div
      v-if="userType !== ''"
      class="content"
    >
      <ValidationObserver
        v-slot="validation"
        tag="div"
      >
        <input-form :errors="validation.errors">
          <div class="field">
            <div class="control">
              <vee-textbox
                v-model="userData.firstName"
                name="First Name"
                placeholder="First Name"
                rules="required"
                required
              />
            </div>
          </div>


          <div class="field">
            <div class="control">
              <vee-textbox
                v-model="userData.lastName"
                name="Last Name"
                placeholder="Last Name"
                rules="required"
                required
              />
            </div>
          </div>

          <div
            v-if="(userType === $USER && curScreen === 'admin-add')
              || (curScreen === 'register' )"
            class="field"
          >
            <div class="control">
              <vee-textbox
                v-model="userData.taxIDNumber"
                placeholder="EIN / SSN"
                name="EIN/SSN"
                rules="required|numeric"
                required
              />
            </div>
          </div>

          <template v-if="userType === $DEPTADMIN">
            <div class="field is-grouped">
              <div class="control is-expanded">
                <vee-textbox
                  v-model="userData.employeeNumber"
                  type="text"
                  name="Employee Number"
                  placeholder="Employee Number"
                  rules="required"
                  required
                />
              </div>
              <div
                v-if="['register', 'admin-add'].includes(curScreen)"
                class="control is-expanded"
              >
                <vee-textbox
                  v-model="userData.departmentNumber"
                  type="text"
                  name="Department Number"
                  placeholder="Department Number"
                  rules="required"
                  required
                />
              </div>
              <div
                v-if="!['register', 'admin-add'].includes(curScreen)"
                class="control is-expanded"
              >
                <vee-textbox
                  v-model="userData.departmentNumber"
                  type="text"
                  name="Department Number"
                  placeholder="Department Number"
                  rules="required"
                  required
                  readonly
                />
              </div>
            </div>
          </template>

          <div
            v-if="userType === $DEPTADMIN || userType === $USER"
            class="field"
          >
            <div class="control">
              <vee-textbox
                v-model="userData.title"
                name="Title"
                placeholder="Title"
                rules="required"
                required
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <vee-textbox
                v-model="userData.emailAddress"
                :aria-disabled="['profile', 'admin-edit'].includes(curScreen)"
                :disabled="['profile', 'admin-edit'].includes(curScreen)"
                name="E-mail Address"
                placeholder="E-mail Address"
                rules="required|email"
                required
              />
            </div>
          </div>

          <div class="field is-grouped">
            <div class="control is-expanded">
              <vee-textbox
                v-model="userData.phoneNumber"
                name="Phone Number"
                placeholder="Phone Number"
                rules="required|validPhone"
                required
                @keyup="formatPhone"
              />
            </div>

            <div class="control is-expanded">
              <vee-textbox
                v-model="userData.phoneExtension"
                name="Phone Extension"
                placeholder="Phone Extension"
              />
            </div>
          </div>

          <template v-if="curScreen === 'register'">
            <div class="field">
              <div class="control">
                <div class="password-requirements has-text-right">
                  <p class="is-marginless">
                    <a @click="showPasswordRequirements">Password Requirements</a>
                  </p>
                </div>
                <vee-textbox
                  id="password"
                  v-model="password"
                  type="password"
                  name="Password"
                  placeholder="Password"
                  autocomplete="off"
                  minlength="8"
                  rules="required"
                  required
                />
              </div>
            </div>

            <div class="field">
              <div class="control">
                <vee-textbox
                  id="passwordConfirm"
                  v-model="confirmPassword"
                  type="password"
                  name="Confirm Password"
                  placeholder="Confirm Password"
                  autocomplete="off"
                  rules="required|confirmed:Password"
                  required
                />
              </div>
            </div>

            <!-- <div class="field">
              <div class="control">
                <vee-checkbox
                  id="disclaimer"
                  v-model="confirmDisclaimer"
                  :value="true"
                  :false-value="null"
                  name="Disclaimer"
                  placeholder="Terms and Conditions"
                >
                  <span>I accept the <a
                    href="#"
                    @click.prevent="EventBus.$emit('show-terms-and-conditions');"
                  >Terms and Conditions</a></span>
                </vee-checkbox>
              </div>
            </div> -->
          </template>
          <div
            class="field is-grouped is-justified-center"
          >
            <div class="control">
              <button
                class="button is-primary"
                :class="{ 'is-loading': updatingProfile }"
                :disabled="updatingProfile"
                @click.prevent="submitForm(validation)"
              >
                Submit
              </button>
            </div>
            <div
              v-if="canDelete()"
              class="control"
            >
              <button
                class="button is-danger"
                :class="{ 'is-loading': updatingProfile }"
                :disabled="updatingProfile"
                @click.prevent="deleteUser"
              >
                Delete
              </button>
            </div>
          </div>
        </input-form>
      </ValidationObserver>

      <template v-if="curScreen === 'profile'">
        <hr class="hr">
        <!-- <ValidationObserver
          ref="update-password-form"
          tag="form"
          @submit.prevent="updatePassword"
        > -->
        <ValidationObserver
          v-slot="validation"
          tag="div"
        >
          <h5 class="has-text-centered">
            Update Password
          </h5>

          <input-form :errors="validation.errors">
            <div class="field">
              <div class="control">
                <vee-textbox
                  id="currentPassword"
                  v-model="currentPassword"
                  type="password"
                  name="Current Password"
                  placeholder="Current Password"
                  autocomplete="off"
                  rules="required"
                  required
                />
              </div>
            </div>

            <div class="field">
              <div class="control">
                <div class="password-requirements has-text-right">
                  <p class="is-marginless">
                    <a @click="showPasswordRequirements">Password Requirements</a>
                  </p>
                </div>
                <vee-textbox
                  id="password"
                  v-model="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  autocomplete="off"
                  minlength="8"
                  rules="required"
                  required
                />
              </div>
            </div>

            <div class="field">
              <div class="control">
                <vee-textbox
                  id="passwordConfirm"
                  v-model="confirmPassword"
                  type="password"
                  name="Repeat Password"
                  placeholder="Repeat Password"
                  autocomplete="off"
                  rules="required|confirmed:password"
                  required
                />
              </div>
            </div>

            <div class="field">
              <div class="control has-text-centered">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': updatingPassword }"
                  :disabled="updatingPassword"
                  @click.prevent="updatePassword(validation)"
                >
                  Update Password
                </button>
              </div>
            </div>
          </input-form>
        </ValidationObserver>
      </template>
    </div>
    <div v-else>
      <p class="has-text-danger has-text-centered">
        Wrong way to open this form, please, refresh the page and try again
      </p>
    </div>
  </div>
</template>
<script>

import InputForm from '@/components/InputForm';

import {
  Textbox,
  Checkbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
  validate,
} from 'vee-validate';

import { required, email, confirmed, numeric } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);
const VeeCheckbox = withValidation(Checkbox);


import { EventBus } from '@/plugins/bus.js';

export default {
  name: "UserForm",
  components: {
    VeeTextbox,
    ValidationObserver,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
    curScreen: {
      type: String,
      default: '',
    },
    userType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: {},
      password: '',
      confirmDisclaimer: null,
      confirmPassword: '',
      currentPassword: '',
      EventBus,
    };
  },
  computed: {
    updatingProfile() {
      return this.$store.state.users.updatingProfile;
    },
    updatingPassword() {
      return this.$store.state.users.updatingPassword;
    },
  },
  watch: {
    formData(newValue) {
      this.setUserData(newValue);
    },
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
    extend('numeric', {
      ...numeric,
    });
    extend('validPhone', {
      validate: value => {
        const regex = new RegExp(/\(\d{3}\)\s\d{3}\s-\s\d{4}/);
        return regex.test(value);
      },
      message: (_, values) => 'phone number error',
      // message: (_, values) => this.$t('form.phoneNumberFormatErrorMsg', values),
    });
    extend('confirmed', {
      ...confirmed,
    });
  },
  beforeMount() {
    this.setUserData(this.formData);
    // this.formatPhone({ 'keycode': 1 });
  },
  methods: {
    canDelete() {
      return this.userData.id !== undefined
        && [ this.$ADMINISTRATOR, 'AchAdministrator' ].includes(this.curUserType)
        && this.userData.id != this.loggedIn.id
        && this.curScreen !== 'profile';
    },
    setUserData(data) {
      if (!data.taxIDNumber) {
        data.taxIDNumber = data.ein;
      }
      this.userData = JSON.parse(JSON.stringify(data));
    },
    reset() {
      this.$refs['register-form'].reset();
      Object.assign(this.$data, this.$options.data.apply(this));
      // Object.keys(this.userData).forEach(key => this.userData[key] = "");
    },
    async updatePassword(validation) {
      console.log('RegistrationForm.vue updatePassword is running');
      // const valid = await this.$refs['update-password-form'].validate();
      const valid = await validation.validate();
      if (!valid) {
        return false;
      }

      const emitData = {
        oldPassword: this.currentPassword,
        newPassword: {
          password: this.password,
        },
      };
      this.$emit('update-password-submit', emitData);
    },
    async submitForm(validation) {
      console.log('submitForm is running');

      // const valid = await this.$refs['register-form'].validate();
      const valid = await validation.validate();

      if (!valid) {
        return false;
      }

      // Setting the correct password format acepted by the server
      this.userData.password = {
        password: this.password,
      };

      this.$emit('form-submit', this.userData);
    },

    deleteUser() {
      this.$emit('delete-user', this.userData);
    },

    showPasswordRequirements () {
      this.$modal.show('dialog', {
        title: 'Password Requirements',
        text: `The Password must contain at least one upper case (A-Z), one lower case (a-z),
          one number (0-9), and  one special character (e.g. !@#$%^&*).<br>
          Also, it must be at least 8 characters long`,
        buttons: [
          {
            title: 'Ok',
            default: true,
            close: true,
          },
        ],
      });
    },

    formatPhone (event) {
      // console.log('RegistrationForm.vue formatPhone is running');
      let useExtension = false;
      if (event.keyCode !== 8) {
        this.userData.phoneNumber = this.$helpers.formatPhone(this.userData.phoneNumber, useExtension);
      }
    },

  },
};
</script>

<style lang="scss">
  .password-requirements {
    p {
      font-size: 0.875rem;
      a {
        font-weight: 400;
      }
    }
  }
</style>
